import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { isScreenSM } from 'utils/screen.utils';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const tooltipPlugin = Chart.registry.getPlugin('tooltip');
(tooltipPlugin as any).positioners.custom = function (_: any, position: any) {
  return {
    x: position.x,
    y: position.y - 20
  };
};

function getChartOptions(): (React.ComponentProps<typeof Line>)["options"] {
  return {
    plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#62C759',
      bodyColor: '#F1F1F1',
      bodyFont: {
        weight: '700',
        size: 24,
        family: 'Inter'
      },
      cornerRadius: 0,
      caretSize: 0,
      padding: 10,
      yAlign: 'bottom',
      displayColors: false,
      // @ts-ignore
      position: 'custom',
      callbacks: {
        title: () => '',
        label: (tooltipItem) => `$ ${(Math.floor(Number(tooltipItem.raw) / 10000000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} B USD`
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      offset: !isScreenSM(),
      ticks: {
        color: '#F1F1F1',
        font: {
          size: isScreenSM() ? 16 : 18
        },
        padding: isScreenSM() ? 10 : 43,
        minRotation: isScreenSM() ? 15 : 0
      }
    },
    y: {
      grid: {
        z: 1,
        color: '#F1F1F1',
        lineWidth: 0.5,
        drawBorder: false,
      },
      beginAtZero: true,
      ticks: {
        callback: (tickValue, index) => {
          return `${(Number(tickValue) / 1000000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${index > 0 ? 'B' : ''} $`;
        },
        color: '#F1F1F1',
        font: {
          size: isScreenSM() ? 14 : 16,
          weight: '700'
        },
        padding: isScreenSM() ? 10 : 24,
        stepSize: 10000000000
      },
      max: 90000000000
    }
  },
  maintainAspectRatio: false,
  onHover: (event, activeEvents) => {
    (event?.native?.target as HTMLElement).style.cursor = activeEvents?.length > 0 ? 'pointer' : 'auto';
  }
  };
};

export default getChartOptions;

